import React from "react";
import styled from "styled-components";
import { Slider, SliderThumb, SliderTrack } from "react-aria-components";

const RangeSlider = ({
    range,
    units = "mi",
    disabled = false,
    sliderValues,
    handleSetRadius,
    handleSetRadiusEnd,
}) => {
    const { minValue, maxValue, step } = sliderValues || {};

    return (
        <SliderWrapper
            className={`d-flex align-items-center ${
                disabled ? "text-muted" : "text-primary"
            }`}
        >
            <div className="me-2">
                {range} {units}
            </div>
            <div className="flex-grow-1 pl-3">
                <Slider
                    defaultValue={range}
                    value={range}
                    minValue={minValue}
                    maxValue={maxValue}
                    step={step}
                    disabled={disabled}
                    onChange={handleSetRadius}
                    onChangeEnd={handleSetRadiusEnd}
                    aria-labelledby="range-slider"
                >
                    <SliderTrack className="position-relative">
                        {({ state }) => (
                            <>
                                {/* track */}
                                <div className="slider-track position-absolute w-100 rounded" />
                                {/* fill */}
                                <div
                                    className="slider-fill position-absolute bg-primary rounded"
                                    style={{
                                        width:
                                            state.getThumbPercent(0) * 100 +
                                            "%",
                                    }}
                                />
                                <SliderThumb className="slider-thumb bg-primary rounded-circle" />
                            </>
                        )}
                    </SliderTrack>
                </Slider>
            </div>
        </SliderWrapper>
    );
};

const SliderWrapper = styled.div`
    .slider-fill,
    .slider-track {
        height: 0.3rem;
        margin-top: -0.15rem;
    }
    .slider-track {
        cursor: pointer;
        background-color: var(--bs-gray-300);
        transition: left 0.3s ease-out, width 0.3s ease-out;
    }
    .slider-thumb {
        height: 15px;
        width: 15px;
        cursor: pointer;
        transition: transform 0.3s ease-out, box-shadow 0.3s ease-out;
        &:active {
            transform: translate(-50%, -50%) scale(1.3) !important;
        }
    }
`;

export default RangeSlider;
