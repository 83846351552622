import React from "react";
import { useSearchQueryParam } from "../../../../hooks/useSearchQueryParam";
import PriceRange from "../../PriceRange";

/**
 * The Price search component.
 * @param {string} label - The label text for the component.
 * @returns The Price Range component that is used in the Horizontal Search.
 */
const PriceSearch = ({ label }) => {
    const [price_min, setPriceMin] = useSearchQueryParam("price_min");
    const [price_max, setPriceMax] = useSearchQueryParam("price_max");

    return (
        <PriceRange
            label={label}
            minPrice={price_min}
            maxPrice={price_max}
            handleSetMaxPrice={setPriceMax}
            handleSetMinPrice={setPriceMin}
        />
    );
};

export default PriceSearch;
