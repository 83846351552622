export const usePageAction = () => {
    const execute = (name = "", attributes = {}) => {
        if ((name, window && window?.newrelic)) {
            newrelic.addPageAction(name, attributes);
        } else if (process.env.REACT_APP_SHOW_TRACKING_LOGGING) {
            console.log("Page Action", name, attributes);
        }
    };

    return {
        execute,
    };
};
