import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useQueryParams } from "use-query-params";
import { useTrackEvent } from "../../../../../hooks/useTrackEvent";
import { locationQueryModel } from "../../../SearchProvider";

export const useRangeSliderSearch = () => {
    const [locationQuery, setLocationQuery] =
        useQueryParams(locationQueryModel);
    const { range } = locationQuery;
    const defaultRange = useSelector((state) => state.search.defaultRange);
    const domain = useSelector(
        (state) => state.root.styling?.nicheDomain2ndLevel
    );
    const [sliderRange, setSliderRange] = useState(range || defaultRange || 20);
    const { trackGTM } = useTrackEvent();

    const isBeach = domain === "beach-homes";
    const sliderValues = {
        minValue: isBeach ? 1 : 10,
        maxValue: isBeach ? 80 : 200,
        step: isBeach ? 1 : 10,
    };

    // This is listening for changes in the range query param
    // These changes may happen in a few other places in the app.
    useEffect(() => {
        if (range !== sliderRange) {
            setSliderRange(range || defaultRange || 20);
        }
    }, [range, defaultRange, setSliderRange]);

    const handleChange = (value) => {
        setSliderRange(value);
    };

    const handleChangeEnd = (value) => {
        // setting range and removing page/mapView
        // when the range is changed
        setLocationQuery({
            ...locationQuery,
            range: value,
            page: undefined,
            mapView: undefined,
        });
        trackGTM({
            event: `userSearch`,
            action: "filter",
            type: "radius",
            category: "user_action",
            value: value,
        });
    };

    return {
        range: sliderRange,
        handleChange,
        handleChangeEnd,
        sliderValues,
    };
};
