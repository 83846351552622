import React, { useState, useEffect, useCallback, useRef } from "react";
import PubSub from "pubsub-js";
import Creatable from "react-select/creatable";
import { useMediaQuery } from "react-responsive";
import { formatCurrency } from "../../../support/helpers";
import CurrencyInput from "./CurrencyInput";

const PriceRangeInput = ({
    value,
    setValue,
    label,
    suffix = "",
    defaultOptions = [],
}) => {
    const isMobile = useMediaQuery({ maxWidth: 992 });
    const [options, setOptions] = useState([]);
    const creatablRef = useRef();
    const stripToNumber = (val) => val.replace(/\D/g, "");

    useEffect(() => {
        const sub = PubSub.subscribe(
            "RESET_SEARCH",
            (subName, params = false) => {
                if (params?.filtersOnly) setInputValue();
            }
        );
        return () => PubSub.unsubscribe(sub);
        // eslint-disable-next-line
    }, []);

    const createOption = useCallback((value, suffix) => {
        const val = parseInt(value);
        return {
            label: isNaN(val) ? value : `${formatCurrency(val)} ${suffix}`,
            value: isNaN(val) ? value : val,
        };
    }, []);

    useEffect(() => {
        setOptions(defaultOptions.map((val) => createOption(val, suffix)));
    }, []);

    return (
        <Creatable
            ref={creatablRef}
            className="flex-grow-1"
            components={{
                Input: (props) => <CurrencyInput suffix={suffix} {...props} />,
            }}
            isClearable
            name={`price${label}Value`}
            options={options}
            defaultValue={value ? createOption(value, suffix) : null}
            placeholder={value ? `${formatCurrency(value)} ${suffix}` : label}
            onChange={(option, action) => {
                if (
                    action.action === "clear" ||
                    option.value === "Any" ||
                    option.value === 0
                ) {
                    setValue();
                } else {
                    setValue(option.value);
                }
            }}
            onCreateOption={(value) => {
                const option = createOption(stripToNumber(value), suffix);
                setValue(option.value);
            }}
            onBlur={(e) => {
                if (e.target.value) {
                    setValue(stripToNumber(e.target.value));
                }
            }}
            maxMenuHeight={isMobile ? "220px" : "400px"}
            value={value ? createOption(value, suffix) : null}
        />
    );
};

export default PriceRangeInput;
