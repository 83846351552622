import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Spinner, ModalBody } from "reactstrap";
import { publish } from "pubsub-js";
import { useParams, withRouter } from "react-router";
import useToggle from "../../../hooks/useToggle";
import IndeterminateButton from "../../IndeterminateButton";
import { useSearchData } from "../../../hooks/useSearchData";
import { getPageType } from "../../../support/helpers";
import { useTrackEvent } from "../../../hooks/useTrackEvent";
import SaveSearchForm from "./SaveSearchForm";
import Drawer from "../../Drawer";
import { useSaveUserSearchMutation } from "../../../services/userEndpoints";
import { toast } from "react-toastify";

export const alertOptions = {
    never: { label: "No alert emails", value: "None" },
    daily: { label: "Daily emails", value: "Daily" },
    weekly: { label: "Weekly emails", value: "Weekly" },
    monthly: { label: "Monthly emails", value: "Monthly" },
};

/**
 * Save Search will show the save search button and save the search when clicked.
 * It will then display a drawer with options to update the name and frequency of alerts.
 */
const SaveSearch = ({ location: { pathname }, disabled }) => {
    const [saveSearch, searchStatus] = useSaveUserSearchMutation();
    const { trackGTM } = useTrackEvent();
    const {
        hasSearched,
        resultsData: results,
        locationData: location,
    } = useSearchData();
    const userID = useSelector((state) => state.root.userSession.userID);
    const isLoggedIn = useSelector((state) => state.root.isLoggedIn);
    const isMobile = useSelector((state) => state.root.isMobile);
    const routeParams = useParams();
    const isState = getPageType(routeParams, "state");
    const firstName = useSelector((state) => state.root.userSession.firstName);
    const [isUpdating, setIsUpdating] = useState(false);
    const [isOpen, toggleIsOpen] = useToggle(false);
    const [waitingForLogin, toggleWaitingForLogin] = useToggle(false);
    const isDisabled = disabled || !hasSearched;

    const handleClick = () => {
        trackGTM({
            event: "buttonClick",
            action: "click",
            type: "Save Search",
            category: "user_action",
        });

        if (isOpen) {
            toggleIsOpen();
        } else if (isLoggedIn) {
            handleSave();
        } else {
            toggleWaitingForLogin();
            if (isMobile) {
                publish("TOGGLE_MOBILE_DRAWER");
            } else {
                publish("TOGGLE_ACCOUNT_DRAWER");
            }
        }
    };

    const resetSaveSearch = () => {
        setIsUpdating(false);
    };

    const handleSave = async (data) => {
        try {
            let search = { ...results?.filters };
            // TODO: The logic for the location search (state) should be moved to the DB.
            if (isState) {
                search = {
                    ...search,
                    distance_miles: location?.myFilterDistanceMiles,
                    zipcode: location?.zipcode || location?.myFilterSearchbox,
                };
            }

            delete search.name;
            const body = JSON.stringify(
                data
                    ? data
                    : {
                          uri: pathname,
                          search,
                      }
            );

            await saveSearch({
                userId: userID,
                body,
            }).unwrap();

            toggleIsOpen();
            setIsUpdating(true);

            trackGTM({
                event: "searchSaved",
                type: "Save Search",
                category: "system_action",
                searchName: search,
            });
        } catch {
            toggleIsOpen(false);
            setIsUpdating(false);

            toast.error(
                "Sorry, we were unable to update your search. Please try again later and if the issue continues contact support."
            );
        }
    };

    // This effect waits till the user is logged in to attempt the save again.
    useEffect(() => {
        if (waitingForLogin && isLoggedIn) {
            if (isMobile) {
                publish("TOGGLE_MOBILE_DRAWER");
                setIsUpdating(true);
                toggleIsOpen();
            } else {
                publish("TOGGLE_ACCOUNT_DRAWER");
            }
            toggleWaitingForLogin();
            handleSave();
        }
        // eslint-disable-next-line
    }, [isLoggedIn]);

    // Reset search when the dialog closes
    useEffect(() => {
        if (!isOpen && searchStatus.data) {
            resetSaveSearch();
        }
        // eslint-disable-next-line
    }, [isOpen]);

    // Reset search when city serach is cleared
    useEffect(() => {
        const reset = PubSub.subscribe("RESET_SEARCH", () => {
            if (isOpen) {
                toggleIsOpen();
            }
        });
        return () => {
            PubSub.unsubscribe(reset);
        };
    }, [isOpen]);

    return (
        <>
            <IndeterminateButton
                className="btn btn-primary ms-auto me-2 px-2 py-2"
                isLoading={!isUpdating && searchStatus?.isLoading}
                onClick={() => handleClick()}
                disabled={isDisabled}
            >
                Save Search
            </IndeterminateButton>
            <Drawer
                isOpen={isOpen}
                toggle={() => toggleIsOpen()}
                headerText={firstName || ""}
            >
                <ModalBody>
                    {searchStatus?.isLoading && (
                        <div className="mb-2 text-center">
                            <Spinner size="sm" /> Saving Search
                        </div>
                    )}
                    <SaveSearchForm
                        userId={userID}
                        toggle={() => toggleIsOpen()}
                        saveSearchData={searchStatus?.data?.lastInserted?.more}
                        alertOptions={alertOptions}
                    />
                </ModalBody>
            </Drawer>
        </>
    );
};

export default withRouter(SaveSearch);
