import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import Toast from "../Toast";
import {
    useDeleteUserPropertyMutation,
    useGetUserQuery,
    useSaveUserPropertyMutation,
} from "../../../services/userEndpoints";
import { useTrackEvent } from "../../../hooks/useTrackEvent";

export const useSaveProperty = ({ listingNumber, location }) => {
    const isLoggedIn = useSelector((state) => state.root.isLoggedIn);
    const userId = useSelector((state) => state.root.userSession.userID);
    const [shouldSaveProperty, setShouldSaveProperty] = useState(false);
    const { trackGTM } = useTrackEvent();
    const [saveProperty] = useSaveUserPropertyMutation();
    const [deleteProperty] = useDeleteUserPropertyMutation();
    const { data: userData = {}, isSuccess } = useGetUserQuery(
        { userId },
        { skip: !userId }
    );

    const toastId = listingNumber;
    const userProperties =
        userData?.userInfo?.dIAccountInfo?.savedProperties?.userProperties ||
        [];
    const shouldAllowSave = isSuccess && isLoggedIn && !shouldSaveProperty;
    const isSaved = userProperties.includes(listingNumber);

    // This effect waits till the user is logged in to attempt the save again.
    useEffect(() => {
        if (
            isLoggedIn &&
            isSuccess &&
            shouldSaveProperty &&
            Array.isArray(userProperties)
        ) {
            // This will only try a save when the user logs in
            save("save");
            setShouldSaveProperty(false);
        }
    }, [isLoggedIn, isSuccess, shouldSaveProperty, userData]);

    const save = async (action) => {
        if (action) {
            const saveAction = action === "save";
            // We only want to save/delete if its in the opposite state
            if (saveAction !== isSaved) {
                try {
                    const execute =
                        action === "save" ? saveProperty : deleteProperty;
                    await execute({ userId, listingNumber }).unwrap();
                    trackClick(action);
                    // When this is dispatched, the property has not been saved yet.
                    displayToast(action === "save", listingNumber);
                } catch (error) {
                    const isLimitExceeded =
                        error?.data?.message?.error?.includes("limit");
                    const content = isLimitExceeded
                        ? "We are unable to save this property. Please remove properties from your Saved Properties to continue."
                        : "Sorry, We we ran into an issue. Please try again later.";
                    const toastData = {
                        render: <Toast content={content} />,
                        type: isLimitExceeded
                            ? toast.TYPE.WARNING
                            : toast.TYPE.ERROR,
                        icon: false,
                        autoClose: 10000,
                    };

                    if (toast.isActive(toastId)) {
                        toast.update(toastId, toastData);
                    } else {
                        toast(<Toast content={content} />, toastData);
                    }
                    return error;
                }
            }
        }
    };

    /**
     * Tracks the click event in Google Analytics.
     */
    const trackClick = (saveAction) => {
        trackGTM({
            event: `buttonClick`,
            action: "click",
            type: "Save Property",
            category: "user_action",
            value: saveAction,
            location,
            listingNumber,
        });
    };

    return {
        shouldAllowSave,
        isSaved,
        save,
        shouldSaveProperty,
        setShouldSaveProperty,
    };
};

/**
 * Displays a toast message indicating if a property was saved or removed.
 * @param {boolean} isSaved - A boolean indicating whether the property was saved or removed.
 * @param {string} toastId - The ID of the toast message.
 */
const displayToast = (isSaved, toastId) => {
    // dismiss any previous toast to prevent crazy toast stacking
    toast.dismiss();
    const toastContent = isSaved
        ? "Property Saved!"
        : "Saved Property Removed.";
    toast.success(<Toast content={toastContent} />, {
        icon: false,
        toastId,
        autoClose: 2000,
    });
};
